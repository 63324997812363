<template>
  <div class="w1200 aboutUs">
    <h1 class="title">关于我们</h1>
    <div class="hasbg" v-html="content"></div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      content:'',
    }
  },
  mounted() {
    this.getAboutUs()
  },
  methods:{
    getAboutUs() {
      this.$api.getAboutUs().then(res=>{
        this.content = res.data.contents
      })
    }
  }
}
</script>
<style>
  .aboutUs img{
    max-width: 100%
  }
</style>
<style lang='stylus' scoped>
  .aboutUs
    position relative
    padding-right: 30px
    &::before
      position: absolute;
      left: 0;
      right: 0;
      border-top: 5px solid #f0f0f0;
      border-right: 5px solid #f0f0f0;
      content: '';
      height: 20%;
      z-index: 1
    &::after
      position: absolute;
      bottom: 0;
      right: 0;
      left:0
      border-bottom: 5px solid #f0f0f0;
      border-right: 5px solid #f0f0f0;
      content: '';
      height: 20%;
      z-index: 1
  .title
    font-size 40px
    margin-bottom 20px
    margin-top: -18px
    display: inline-block
    background: #fff
    position relative
    padding-right 40px
    z-index: 2
  .hasbg
    position relative
    background: url('~@/assets/images/bg1.png') no-repeat center top;
    background-size: contain;
    margin-top: 50px;
    padding-bottom: 50px;
    z-index 2
    font-size: 16px
  .txt-p
    line-height: 36px
    color #222
    font-size 18px
    padding-right 30px
    margin-bottom 30px
</style>